<template>
  <div>
    <div class="page-heading" style="margin-bottom: 0px;">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-4">
          <div class="heading-content">
            <div class="heading-title">
              <h2>Orçamentos</h2>
              <p class="hidden-sm">
                Crie orçamentos de suas despesas mensais e defina limites de gastos  🤑
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-8">
          <button class="btn btn-danger pull-right action-income w49_5-m mBottom-10-m" type="button" style="margin-left: 2px; margin-right: 2px;" @click="createTransaction('create-transaction-modal', 'expense', false)"><span><i class="mdi mdi-plus-circle-outline"></i></span> Lançar despesa </button>
          <button class="btn btn-success pull-right action-income w49_5-m m-left-none-m mBottom-10-m" type="button" @click="createBudget('create-budget-modal')"><span><i class="mdi mdi-plus-circle-outline"></i></span> Novo orçamento </button>
        </div>
      </div>
      <hr>
    </div>
    <div class="row">
      <div class="relative">
        <div class="include-card-expense">
          <input type="checkbox" id="for_is_card" v-model="no_card" @change="request">
          <label style="margin-top: 10px; margin-right: 15px;" for="for_is_card" class="to-left">Despesas de cartão</label>

          <input type="checkbox" id="for_is_card_subca" v-model="subcategories" @change="request">
          <label style="margin-top: 10px;" for="for_is_card_subca" class="to-left">Subcategorias</label>
        </div>
      </div>
      <div class="col-lg-12" style="margin-top: 50px;">
        <div class="owl-nav" style="position: relative; width: 35%; top: 0; margin: 0 auto; margin-top:20px;margin-bottom:23px;">
          <calendar-co :month="monthStart" :year="yearStart" :callBack="changeDate" :navigateTo="navigateDateTo"></calendar-co>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <div class="card">
          <div class="card-header">
            <h4>Total gasto</h4>
          </div>
          <div class="card-body" style="padding-top: 0;">
            <section class="text-center">
              <h1 class="reports-expenses text-left font-w-600 ng-binding" style="margin-top: 0px;">{{budgets.report.budgets.totalSpent | currency }}</h1>
              <hr>
              <div class="chart-insights  text-left">
                <p>Meta total</p>
                <h4 style="margin: 5px;">
                {{budgets.report.budgets.total | currency}}</h4>
              </div>
            </section>
            <div></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <div class="card">
          <div class="card-header">
            <h4>Total disponível</h4>
          </div>
          <div class="card-body" style="padding-top: 5px;">
            <div class="transaction-progress">
              <div class="item mt-5">
                <strong class="pull-right ng-binding">{{budgets.report.budgets.availablePercent}}%</strong>
                <p class="text-muted"> <i class="mdi mdi-checkbox-blank-circle-outline text-primary" :class="{'text-danger': budgets.report.budgets.spentPercent > 85, 'text-warning': budgets.report.budgets.spentPercent > 70 && budgets.report.budgets.spentPercent < 85, 'text-primary': budgets.report.budgets.spentPercent < 70 }"></i> Status</p>
                <div class="progress progress-bar-success-alt">
                  <div class="progress-bar progress-bar-success" role="progressbar" :class="{'progress-bar-danger': budgets.report.budgets.spentPercent > 85, 'progress-bar-warning': budgets.report.budgets.spentPercent > 70 && budgets.report.budgets.spentPercent < 85, 'progress-bar-success': budgets.report.budgets.spentPercent < 70 }" :style="`width:${budgets.report.budgets.spentPercent}%`">
                  </div>
                </div>
              </div>
            </div>
            <section class="text-center">
              <div class="item mt-5">
                 <hr>
                <h1 class="text-left ng-binding text-primary" style="margin-top: 0px; font-weight:100;">{{budgets.report.budgets.available}}</h1>
              </div>
            </section>
            <div style="padding-top:2px;"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-4 col-md-4 col-xs-12">
        <div class="card">
          <div class="card-body" style="padding: 0px; height: 200px;">
            <section  class="text-center hide">
              <svg
                width="120"
                height="120"
                xmlns="http://www.w3.org/2000/svg"
                class="circle-chart"
                viewBox="-3 0 40 30"
              >
                <circle
                  class="circle-chart__background"
                  stroke="#efefef"
                  stroke-width="2"
                  fill="none"
                  cx="16.91549431"
                  cy="16.91549431"
                  r="15.91549431"
                />
                <circle
                  class="circle-chart__circle"
                  stroke="#ff1a1a"
                  stroke-width="2"
                  :stroke-dasharray="`${budgets.report.budgets.spentPercent},100`"
                  stroke-linecap="round"
                  fill="none"
                  cx="16.91549431"
                  cy="16.91549431"
                  r="15.91549431"
                />
                <g class="circle-chart__info">
                  <text
                    class="circle-chart__percent font-w-100"
                    x="16.91549431"
                    y="20"
                    alignment-baseline="central"
                    text-anchor="middle"
                    font-size="8"
                  >
                    {{budgets.report.budgets.spentPercent}}%
                  </text>
                </g>
              </svg>
            </section>
            <v-chart class="chart" :option="optionBudget" />
          </div>
        </div>
      </div>
      
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="tab-content relative">
          <div class="cashflow-print" @click="print"><i class="fa fa-print hide"></i></div>
          <div class="tab-pane printable" :class="{'show': tabActive == 'tab-all'}" v-if="tabActive == 'tab-all'">
            <div class="card table-responsive longer" style="border-radius: 10px !important; margin-bottom: 0px;">
              <h3 class="display-4">Orçamentos</h3>
              <hr>
              <div class="row ng-scope" v-if="budgets.budgets.length == 0">
                <img :src="`assets/images/budgeting.png`" class="nothing-image w-15p" style="width: 10% !important">
                <h3 class="nothing-text">Nenhum orçamento encontrado</h3>
              </div>
              <ul class="list-group list-budget" v-if="budgets.budgets">
                <li class="list-group-item ng-scope no-border" v-for="(item, i) in budgets.budgets" :key="i" @click="budgetDetail('budget-detail-modal', item)">
                  <div class="row" style="margin-bottom: 0px;">
                    <div class="category-list-color" :style="`background: ${item.color ? item.color : '#54b78e'}`"></div>
                    <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12 p-left-25">
                      <span class="text-success"><span class="ng-binding"><strong>{{ item.category ? item.category.name : item.title }}</strong></span></span>
                      <span class="ng-binding transaction-note hide"><br></span>
                      <div class="transaction-progress">
                        <div class="item" style="margin:0px; width: 100%; margin-top: 12px;">
                          <strong class="pull-right ng-binding"> {{item.progress}}%</strong>
                          <p class="text-muted" style="font-size: 18px;">{{item.spent | currency }} / {{item.value | currency }}</p>
                          <progress-day :progress="item.progress" :day_progress="item.day_progress" :day_end="item.day_end" :day_start="item.day_start"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12" v-if="item.categories.length > 0">
                      <ul class="ul-category-icon">
                        <li v-for="(item, i) in item.categories" :key="i">
                          <div v-tooltip.top="item.name" class="ul-category-icon-item to-left" :style="`background: ${item.color}`" :class="{ active: item.selected }">
                            <img :src="`/assets/images/icons/png/${item.icon}.png`">
                          </div>
                          <div style="clear: both;"></div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <create-budget-modal id="create-budget-modal" @saveBudget="request"/>
    <edit-budget-modal id="edit-budget-modal" @saveBudget="request"/>
    <budget-detail-modal id="budget-detail-modal"/>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import { GaugeChart } from "echarts/charts";
import CalendarCo from '../components/Calendar.vue';
import CreateBudgetModal from '@/components/CreateBudgetModal.vue';
import EditBudgetModal from '@/components/EditBudgetModal.vue';
import BudgetDetailModal from '@/components/BudgetDetailModal.vue';
import VChart, { THEME_KEY } from "vue-echarts";
import ProgressDay from '../components/ProgressDay.vue';

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import AppService from '@/services/app.service';
import pace from 'pace-js';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import Utils from '@/models/utils';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
  GaugeChart,
]);

export default {
  name: 'Home',
  components: {
    CalendarCo,
    CreateBudgetModal,
    VChart,
    EditBudgetModal,
    BudgetDetailModal,
    ProgressDay,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      optionBudget: {
        color: '#37a2da',
        series: [
          {
            type: 'gauge',
            progress: {
              show: true,
              width: 10
            },
            axisLine: {
              lineStyle: {
                width: 10
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 4,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            axisLabel: {
              distance: 15,
              color: '#999',
              fontSize: 10
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 20,
              itemStyle: {
                borderWidth: 10
              }
            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              fontSize: 25,
              offsetCenter: [0, '70%'],
              formatter: '{value}%',
            },
            data: [
              {
                value: 0
              }
            ]
          }
        ]
      },
      monthStart: 0,
      cards: [],
      monthName: '',
      categories: [],
      wallets: [],
      subcategories: true,
      filters: [
        {
          field: '',
          values: [
          ],
        },
      ],
      fields: [
        { label: 'Contas', value: 'account_id' },
        { label: 'Categoria', value: 'category' },
      ],
      filterAll: [
        { label: 'Cartão', value: 'card' },
        { label: 'Conta', value: 'account' },
      ],
      optionsDate: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 360,
        },
        locale: {
          format: 'DD/MM/YYYY',
          cancelLabel: 'Cancelar',
          // applyLabel: 'Filtrar',
          applyLabel: 'Ok',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      tabActive: 'tab-all',
      hourType: 'sun',
      hourTypeText: 'Bom dia',
      totalAccount: 0,
      from: '',
      to: '',
      no_card: true,
      month: '',
      report: {},
      budgets: {budgets:[], report: {budgets: {}}},
      isLoading: true,
      yearStart: 2024,
      monthsList: [
        { id: '01', name: 'Janeiro', index: 0},
        { id: '02', name: 'Fevereiro', index: 1},
        { id: '03', name: 'Março', index: 2},
        { id: '04', name: 'Abril', index: 3},
        { id: '05', name: 'Maio', index: 4},
        { id: '06', name: 'Junho', index: 5},
        { id: '07', name: 'Julho', index: 6},
        { id: '08', name: 'Agosto', index: 7},
        { id: '09', name: 'Setembro', index: 8},
        { id: '10', name: 'Outubro', index: 9},
        { id: '11', name: 'Novembro', index: 10},
        { id: '12', name: 'Dezembro', index: 11},
      ],
      months: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
      ],
    };
  },
  provide: {
    [THEME_KEY]: "light",
  },
  mounted () {
    window.scrollTo(0, 0);
    this.monthStart = new Date().getMonth() + 1;
  },
  created() {
    moment.locale('pt_BR');
    this.yearStart = Number(moment(new Date()).format('YYYY'));
    this.monthStart = new Date().getMonth();
    this.month      = new Date().getMonth();

    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
    this.request();

    this.$root.$on('register.transaction', this.request);
    this.$root.$on('call_remove_modal', this.removeBudget);
    this.$root.$on('call_edit_modal', this.editBudget);
  },
  methods: {
    navigateDateTo (month) {
      this.from = `${month.year}-${month.id}-01`;
      this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
      this.month = month.id;
      this.monthStart = month.index;
      this.request();
    },
    deleteBudget(id) {
      AppService.deleteBudget(id).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: "Sucesso",
            content: "Orçamento removido!",
            type: "success",
          });
          this.request();
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    removeBudget(id) {
      Swal.fire({
        title: `Remover orçamento?`,
        text: 'Tem certeza que deseja remover esse orçamento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.deleteBudget(id);
        }
      });
    },
    createTransaction(id, type, isCard) {
      const data = {
        isCard: isCard,
        type: type,
        card: null,
        bill_type: {
          type: 'M',
        },
        is_recurrence: false,
        invoice_pay: false,
        invoice: null,
        account: null,
        check_sub: true,
      };
      this.$root.$emit('show.modal', id, data);
    },
    createBudget(id) {
      const data = {
        month: this.month,
        date: this.from,
        monthStart: this.monthStart,
        yearStart: this.yearStart,
        color: '#7b93a4',
        value: 0,
      };
      this.$root.$emit('show.modal', id, data);
      setTimeout(() => {
        console.log('monthStart', this.monthStart);
        let index = Utils.getMonthIndex(parseInt(this.monthStart), parseInt(this.yearStart));
        this.$root.$emit('calendar.callNext', index);
      }, 500);
    },
    editBudget(item) {
      const data = {
        id: item.id,
        month: item.month,
        year: item.year,
        title: item.title,
        categories: item.categories,
        date: `${moment(new Date()).format('YYYY')}-${item.month}-01`,
        value: item.value,
      };
      this.$root.$emit('show.modal', 'edit-budget-modal', data);
      setTimeout(() => {
        let index = Utils.getMonthIndex(parseInt(item.month), parseInt(item.year));
        this.$root.$emit('calendar.callNext', index);
      }, 500);
    },
    budgetDetail(id, item) {
      item.from = this.from;
      item.to = moment(this.from, "YYYY-MM-DD").endOf('month');
      item.no_card = this.no_card;
      item.subcategories = this.subcategories;
      this.$root.$emit('show.modal', id, item);
    },
    changeDate(date) {
      console.log(date);
      if (date) {
        this.from = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.to = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.request();
      }
    },
    request() {
      pace.start();
      this.optionBudget.color = '#37a2da';
      AppService.getBudgets({
        from: this.from,
        to: this.to,
        no_card: !this.no_card,
        check_sub: true,
        subcategories: this.subcategories,
      }).then(
        (response) => {
          if (response.report) {
            this.budgets = response;
            this.optionBudget.series[0].data[0].value = response.report.budgets.spentPercent;
            if (response.report.budgets.spentPercent >= 70)  {
              this.optionBudget.color = '#fac858';
            }
            if (response.report.budgets.spentPercent >= 90)  {
              this.optionBudget.color = '#ee6666';
            }
          }
          if (response.status == 301) {
            this.$router.push({ name: 'Premium', params: { expire: true }});
          }
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
    },
    print () {
      window.print();
    },
    filterRequest() {
      this.request();
    },
  },
};
</script>

<style scoped lang="scss">
.cashflow-print {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.page-heading {
  margin-bottom: 0px;
}
.chart {
  height: 250px;
  top: -5px;
  left: 0;
  position: absolute;
}
.chart-line {
  height: 350px;
  margin-top: 0px;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-container {
  height : 500px;
}
.figure-stats {
  background-color: #F9F9F9;
  border: 1.2px solid #E7EAEE;
  margin-top: 0px;
}
.card-header {
  border-bottom: none;
}
.figure-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.heading-title {
  padding-left: 0px;
}
.total-amount {
  margin-top: 10px;
  display: block;
}
.btn-remove-filter {
  height: 35px !important;
  border-radius: 50%;
  width: 35px;
  padding: 0px !important;
  text-align: center;
}
.btn-remove-filter i {
  margin-right: 0;
}
.list-budget .transaction-progress .progress {
  height: 8px;
}
.btn-edit-category {
  width: 40%;
}
.btn-remove-category {
  width: 40%;
}
.budget-buttons {
  position: absolute;
  top: 0;
  width: 120px;
  right: 0;
}
@media only screen and (max-width: 570px){
  .budget-buttons {
    top: -40px !important;
  }
}
.include-card-expense {
  position: absolute;
  left: 20px;
  z-index: 1;
}
.list-group-item {
  padding-bottom: 25px !important;
  cursor: pointer;
  margin-bottom: 1px !important;
}
.list-group-item:hover {
  background: #f9f9f9 !important;
}
</style>
