class Utils {
  isNumber(n) {
    this.a = false;
    this.a = /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    return this.a;
  }

  isMidia(link) {
    this.a = new Map([['jpg', 'jpeg', 'img'], ['gif', 'img'], ['mp4', 'video'], ['3gp', 'video']]);

    const url = new URL(link);
    const extension = url.pathname.split('.')[1];

    return this.a.get(extension);
  }

  getMonthIndex(month, year) {
    let months = [
      { id: '01', name: 'Janeiro', index: 1},
      { id: '02', name: 'Fevereiro', index: 2},
      { id: '03', name: 'Março', index: 3},
      { id: '04', name: 'Abril', index: 4},
      { id: '05', name: 'Maio', index: 5},
      { id: '06', name: 'Junho', index: 6},
      { id: '07', name: 'Julho', index: 7},
      { id: '08', name: 'Agosto', index: 8},
      { id: '09', name: 'Setembro', index: 9},
      { id: '10', name: 'Outubro', index: 10},
      { id: '11', name: 'Novembro', index: 11},
      { id: '12', name: 'Dezembro', index: 12},
    ];

    let newMonthCalendar = [];
    for (let index = (year - 1); index <= year + 1; index++) {
      months.forEach((month) => {
        newMonthCalendar.push({
          year: index,
          month: month.name,
          index: month.index,
          id: month.id,
        });
      });
    }
    let index = 0;
    let indexReturn = 0;
    newMonthCalendar.forEach((date) => {
      if(month == date.index && year == date.year) {
        indexReturn = index;
      }
      index++;
    });
    return indexReturn;
  }
}
export default new Utils();
